import React, { useState } from 'react'
import { Share, X } from '@tamagui/lucide-icons'
import PATHS_MERCHANT from '@centrito/common/pathsMerchant'
import { usePathname } from '@centrito/app/hooks/usePathname'
import { Button, XStack, isClient } from '@centrito/ui/src'
import { DefaultTextSecondary, SemiBoldTextSecondary } from '@centrito/ui/src/components/text'

const VISIBLE_PATHS = [PATHS_MERCHANT.Site.Home, PATHS_MERCHANT.Site.MyOrders] as string[]

export const MerchantAddToHomeScreenIOS: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  const currentPath = usePathname()

  if (!isClient || !isVisible || !VISIBLE_PATHS.includes(currentPath)) return null

  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

  if (!iOSSafari || navigator.standalone) return null

  return (
    <XStack
      zIndex={999_999}
      backgroundColor="$pureWhite"
      borderRadius={8}
      shadowColor="$translucentBlack25"
      shadowOffset={{ width: 0, height: 12 }}
      shadowRadius={18}
      $platform-web={{
        position: 'fixed',
        bottom: 0,
      }}
      padding={16}
    >
      <DefaultTextSecondary fontSize={16} verticalAlign="center">
        Instala esta aplicación web en tu iPhone para enterarte de los últimos pedidos a través de
        notificaciones. Es fácil, simplemente toca <Share size={20} color="#007AFF" /> y luego{' '}
        <SemiBoldTextSecondary>&quot;Añadir a la pantalla de inicio&quot;</SemiBoldTextSecondary>.
      </DefaultTextSecondary>

      <Button
        icon={<X size={16} />}
        width={30}
        height={30}
        padding={0}
        onPress={(): void => setIsVisible(false)}
      />
    </XStack>
  )
}
