import React, { useEffect } from 'react'
import { type NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import isNil from 'lodash.isnil'
import { trpc } from '@centrito/app/api/trpc/index.web'
import { MerchantAddToHomeScreenIOS } from '@centrito/app/components/MerchantAddToHomeScreenIOS'
import {
  FACEBOOK_PIXEL_SCRIPT_JS,
  META_CONVERSION_PIXEL_ID,
  ROOT_URL,
  isProd,
} from '@centrito/app/config'
import { ONE_SIGNAL_APP_ID } from '@centrito/app/config/index.web'
import Provider from '@centrito/app/provider/merchant'
import { notificationsClient } from '@centrito/app/utils/notifications'

// eslint-disable-next-line @typescript-eslint/no-require-imports
require('../styles/styles.less')

interface MetaTagsGeneratorSEOProps {
  title?: string
  description?: string
  urlPath?: string
  urlImage?: string
}

const MetaTagsGeneratorSEO: NextPage<MetaTagsGeneratorSEOProps> = ({
  title,
  description,
  urlPath,
  urlImage,
}) => {
  const finalTitle = `${title || 'Comerciantes Centrito'}`
  const finalUrlPath = urlPath || '/'

  return (
    <Head>
      <title>{finalTitle}</title>
      <meta name="title" content={finalTitle} key="title" />

      {!isNil(description) && (
        <React.Fragment>
          <meta name="description" content={description} key="description" />
          <meta property="og:description" content={description} key="og:description" />
          <meta property="twitter:description" content={description} key="twitter:description" />
        </React.Fragment>
      )}

      {!isNil(urlPath) && <link rel="canonical" href={`${ROOT_URL}${finalUrlPath}`} key="canon" />}

      {!isNil(urlImage) && (
        <React.Fragment>
          <meta property="og:image" content={urlImage} key="og:image" />
          <meta property="twitter:image" content={urlImage} key="twitter:image" />
          <link rel="icon" type="image/png" href={urlImage} />
        </React.Fragment>
      )}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover,  maximum-scale=1, user-scalable=no"
      />

      {/* Facebook meta tags */}
      <meta property="og:type" content="website" key="og:type" />
      <meta key="og:url" property="og:url" content={`${ROOT_URL}${finalUrlPath}`} />
      <meta property="og:title" content={finalTitle} key="og:title" />

      <meta property="fb:app_id" content={META_CONVERSION_PIXEL_ID} key="fb:app_id" />

      {/* Twitter meta tags */}
      <meta property="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta property="twitter:url" content={`${ROOT_URL}${finalUrlPath}`} key="twitter:url" />
      <meta property="twitter:title" content={finalTitle} key="twitter:title" />
    </Head>
  )
}

const CustomApp = (appProps: AppProps): JSX.Element => {
  const { Component, pageProps } = appProps
  const { SEOProperties } = pageProps

  useEffect(() => {
    if (isProd) {
      notificationsClient.initialize(ONE_SIGNAL_APP_ID)
    }
  }, [])

  return (
    <>
      <MetaTagsGeneratorSEO
        title={SEOProperties?.title}
        description={SEOProperties?.description}
        urlImage={SEOProperties?.urlImage}
        urlPath={SEOProperties?.urlPath}
      />
      <noscript>
        {/* eslint-disable @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${META_CONVERSION_PIXEL_ID}&ev=PageView&noscript=1`}
          alt="Facebook Pixel"
        />
      </noscript>
      <Script id="load-facebook-pixel" strategy="lazyOnload">
        {FACEBOOK_PIXEL_SCRIPT_JS}
      </Script>

      <Provider {...appProps}>
        <MerchantAddToHomeScreenIOS />
        <Component {...pageProps} />
      </Provider>
    </>
  )
}

// https://nextjs.org/docs/basic-features/typescript#custom-app
// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` d fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//   return { ...appProps }
// }

export default trpc.withTRPC(CustomApp)
