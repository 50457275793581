import React from 'react'
import { type TextProps } from 'tamagui'
import { DefaultTextSecondary } from './DefaultTextSecondary'

export const SemiBoldTextSecondary: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <DefaultTextSecondary fontWeight="600" {...rest}>
      {children}
    </DefaultTextSecondary>
  )
}
