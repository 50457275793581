import isNil from 'lodash.isnil'
import OneSignal from 'react-onesignal'

class NotificationsClient {
  private isInitialized: boolean

  constructor() {
    this.isInitialized = false
  }

  public initialize(appId: string): void {
    OneSignal.init({
      appId,
    })

    this.isInitialized = true
  }

  public logout(): void {
    OneSignal.logout()
  }

  public login(userId: string): void {
    OneSignal.login(userId)
  }

  public getSubscriptionId(): string {
    return isNil(OneSignal.User.PushSubscription.id) ? '' : OneSignal.User.PushSubscription.id
  }

  public addTags(tags: { [key: string]: string }): void {
    OneSignal.User.addTags(tags)
  }

  public async promptPush(): Promise<void> {
    OneSignal.Slidedown.promptPush()
  }

  public getIsInitialized(): boolean {
    return this.isInitialized
  }
}

export const notificationsClient = new NotificationsClient()
