import React, { useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import posthog from 'posthog-js'
import { PostHogProvider as _PostHogProvider } from 'posthog-js/react'
import { POSTHOG_API_KEY, isProd } from '@centrito/app/config'
import Sentry from '@centrito/app/sentry/merchant'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import {
  ANALYTICS_ENABLED,
  POSTHOG_API_HOST,
  POSTHOG_ENABLED,
} from '@centrito/app/utils/services/analytics/posthog/utils/constants'

const PostHogProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  useEffect(() => {
    if (POSTHOG_ENABLED) {
      posthog.init(POSTHOG_API_KEY, {
        api_host: POSTHOG_API_HOST,
        loaded: (posthog) => {
          if (!ANALYTICS_ENABLED) posthog.opt_out_capturing()
          else {
            if (posthog.has_opted_out_capturing()) posthog.opt_in_capturing()
            if (!isProd) posthog.debug()
            const posthogDistinctId = posthog.get_distinct_id()
            posthogClient.capturePageView() // Capture first page view
            if (!isEmpty(posthogDistinctId) && !isNil(posthogDistinctId)) {
              Sentry.setUser({ id: posthogDistinctId })
            }
          }
        },
        session_recording: {
          maskAllInputs: false,
        },
      })
    }
  })

  return <_PostHogProvider client={posthog}>{children}</_PostHogProvider>
}

export default PostHogProvider
