import React from 'react'
import type { AppProps } from 'next/app'
import { ToastProvider, ToastViewport } from '@tamagui/toast'
import PaginationProvider from '@centrito/app/provider/common/pagination'
import PortalProvider from '@centrito/app/provider/common/portal'
import SupabaseProvider from '@centrito/app/provider/common/supabase'
import PostHogProvider from './posthog'
import ReduxProvider from './redux'
import ThemeProvider from './theme'
import { TRPCProvider } from './trpc'

const Provider: React.FC<
  AppProps & {
    children: React.ReactNode
  }
> = ({ children, ...appProps }) => {
  return (
    <PostHogProvider>
      <SupabaseProvider {...appProps}>
        <TRPCProvider>
          <ThemeProvider {...appProps}>
            <ToastProvider>
              <ReduxProvider>
                <PortalProvider>
                  <PaginationProvider>{children}</PaginationProvider>
                </PortalProvider>
              </ReduxProvider>
              <ToastViewport
                bottom={120}
                justifyContent="center"
                display="flex"
                alignSelf="center"
                multipleToasts
              />
            </ToastProvider>
          </ThemeProvider>
        </TRPCProvider>
      </SupabaseProvider>
    </PostHogProvider>
  )
}

export default Provider
